// libs
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// redux
import { budgetActions } from '../../../store/index';

// css
import styles from './BudgetInput.module.css';

// helpers
import { formatNumber } from '../../../helpers/formatter';

const BudgetInputRedux = (props) => {
  const { divNumber, subdivNumber, itemNumber } = props;
  const dispatch = useDispatch();

  const inputValue = useSelector(
    (state) =>
      state[divNumber].subdivisions[subdivNumber].items[itemNumber].value
  );

  const isShowing = useSelector(
    (state) =>
      state[divNumber].subdivisions[subdivNumber].items[itemNumber].isShowing
  );

  // this stays local
  // const [isValidNumber, setIsValidNumber] = useState(null); // future error handling

  const changeHandler = (e) => {
    const num = formatNumber(e.target.value, false);
    if (!isNaN(Number(num.replace(/,/g, '')))) {
      dispatch(
        budgetActions.updateValue({
          value: num,
          divNum: divNumber,
          subdivNum: subdivNumber,
          itemNum: itemNumber,
        })
      );
      dispatch(
        budgetActions.updateTotals({
          divNum: divNumber,
          subdivNum: subdivNumber,
        })
      );
    } else {
    }
  };

  return (
    <div className={styles['input-box']}>
      <label
        className={`${styles['label']} ${!isShowing && styles['hidden-label']}`}
        htmlFor={props.input.id}
      >
        {props.label}
      </label>
      {isShowing && (
        <input
          onChange={changeHandler}
          // onBlur={blurHandler}
          value={inputValue && '$ ' + formatNumber(inputValue)}
          className={styles['input']}
          {...props.input}
        />
      )}
    </div>
  );
};

export default BudgetInputRedux;
