// libs
import React from 'react';

// components
import Hero from '../components/Hero/Hero';
import Features from '../components/Features/Features';
import About from '../components/About/About';
import HeroModern from '../components/Hero/HeroModern';

const Home = () => {
  return (
    <React.Fragment>
      <HeroModern />
      <Features />
      {/* <About /> */}
    </React.Fragment>
  );
};
export default Home;
