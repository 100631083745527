// libs
import React, { useState } from 'react';
import { SyncLoader } from 'react-spinners';
import { send } from 'emailjs-com';

// components
import Button from '../UI/Button';
import { Input, TextArea } from '../UI/Input';

// css
import styles from './ContactForm.module.css';

// hooks
import useInputValidation from '../../hooks/use-input';

const checkFormHasError = (states) => {
  const errorArray = [];
  for (let obj of states) {
    for (let key in obj) {
      if (key.includes('isValid')) {
        errorArray.push(obj[key]);
      }
    }
  }
  return errorArray.some((isValid) => isValid === false);
};

const isNameValid = (name) => {
  return name !== '';
};

const isEmailValid = (email) => {
  const validRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return email !== ''
    ? email.toLowerCase().match(validRegex)
      ? true
      : false
    : false;
};

const isURLValid = (url) => {
  const validURLRegex =
    /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/;
  // because url is optional if the user doesn't put anything down
  // that should be okay and will not throw an error.
  if (url === '') return true;
  if (url !== '') {
    return url.match(validURLRegex) ? true : false;
  }
};

const ContactForm = () => {
  const [sending, setSending] = useState(false);
  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);
  const [sent, setSent] = useState(false);
  const [firstTime, setFirstTime] = useState(true);

  const {
    state: firstNameState,
    valueBlurHandler: firstNameBlurHandler,
    valueChangeHandler: firstNameChangeHandler,
  } = useInputValidation(isNameValid);

  const {
    state: lastNameState,
    valueBlurHandler: lastNameBlurHandler,
    valueChangeHandler: lastNameChangeHandler,
  } = useInputValidation(isNameValid);

  const {
    state: emailState,
    valueBlurHandler: emailBlurHandler,
    valueChangeHandler: emailChangeHandler,
  } = useInputValidation(isEmailValid);

  const {
    state: urlState,
    valueBlurHandler: urlBlurHandler,
    valueChangeHandler: urlChangeHandler,
  } = useInputValidation(isURLValid);

  const {
    state: companyNameState,
    valueBlurHandler: companyNameBlurHandler,
    valueChangeHandler: companyNameChangeHandler,
  } = useInputValidation(isNameValid);

  const allRequiredStates = [
    firstNameState,
    lastNameState,
    emailState,
    companyNameState,
  ];

  const messageChangeHandler = (e) => {
    setMessage(e.target.value);
  };

  const submitContactFormHandler = (e) => {
    e.preventDefault();
    setFirstTime(false);

    if (!checkFormHasError(allRequiredStates)) {
      setError(false);
      const sendData = {
        fromName: `${firstNameState.val} ${lastNameState.val}`,
        emailFrom: emailState.val,
        companyName: companyNameState.val,
        companyWebsite: urlState.val,
        message,
      };

      send('service_z9sfwmq', 'template_x311sz4', sendData, 'iEaD6nFRqfw1RML1P')
        .then((response) => {
          setSending(true);
          console.log('SUCCESS!', response.status, response.text);
          setTimeout(() => {
            setSending(false);
            setSent(true);
          }, 1000);
        })
        .catch((error) => {
          setSent(false);
          setSending(false);
          console.log('FAILED...', error);
        });
    } else {
      setError(true);
    }
  };
  console.log(sent, sending, error, firstTime);
  return (
    <React.Fragment>
      {!firstTime && sent && !sending && !error && (
        <div className={styles['spinner-container']}>
          <span>Thanks! Somebody will be reaching out shortly.</span>
        </div>
      )}

      {error && (
        <div className={styles['spinner-container']}>
          <p>Please fill in the required fields and resubmit.</p>
        </div>
      )}

      {sending && !error && (
        <div className={styles['spinner-container']}>
          <SyncLoader color="var(--stak-blue-hover)" />
        </div>
      )}

      {!sending && !sent && (
        <form
          action="https://stak.cc"
          method="post"
          encType="application/x-www-form-urlencoded"
          autoComplete="on"
          onSubmit={submitContactFormHandler}
          className={styles['form']}
        >
          <Input
            error={{
              valid: firstNameState.isValid,
              touched: firstNameState.isTouched,
            }}
            input={{
              label: 'First name*',
              id: 'first-name',
              type: 'text',
              placeholder: 'Jane',
              onBlur: firstNameBlurHandler,
              onChange: firstNameChangeHandler,
            }}
          />
          <Input
            error={{
              valid: lastNameState.isValid,
              touched: lastNameState.isTouched,
            }}
            input={{
              label: 'Last name*',
              id: 'last-name',
              type: 'text',
              placeholder: 'Reynolds',
              onBlur: lastNameBlurHandler,
              onChange: lastNameChangeHandler,
            }}
          />
          <Input
            error={{
              valid: emailState.isValid,
              touched: emailState.isTouched,
            }}
            input={{
              label: 'Email*',
              id: 'email',
              type: 'email',
              placeholder: 'jane@example.com',
              onBlur: emailBlurHandler,
              onChange: emailChangeHandler,
            }}
          />
          <Input
            error={{
              valid: companyNameState.isValid,
              touched: companyNameState.isTouched,
            }}
            input={{
              label: 'Company name*',
              id: 'company-name',
              type: 'text',
              placeholder: 'example',
              onBlur: companyNameBlurHandler,
              onChange: companyNameChangeHandler,
            }}
          />
          <Input
            error={{
              valid: urlState.isValid,
              touched: urlState.isTouched,
            }}
            input={{
              label: 'Company website',
              id: 'company-website',
              type: 'url',
              placeholder: 'example.com',
              onBlur: urlBlurHandler,
              onChange: urlChangeHandler,
            }}
          />
          <TextArea
            input={{
              label: 'Message',
              id: 'message',
              rows: 3,
              placeholder: 'Tell us a more about your specific needs',
              onChange: messageChangeHandler,
            }}
          />
          <div className={styles['form-footer']}>
            <Button
              className={styles['button']}
              type="submit"
              buttonText="Contact Us"
              onClick={submitContactFormHandler}
            />
          </div>
        </form>
      )}
    </React.Fragment>
  );
};

export default ContactForm;
