// libs
import { Fragment, useEffect, useState } from 'react';

// components
import Button from '../Button';
import { ReactComponent as Info } from '../../../assets/icons/Info.svg';

// css
import styles from './InputProcessInvoice.module.css';

// helpers
import { formatNumber } from '../../../helpers/formatter';
import { useMediaQuery } from 'react-responsive';

const InputProcessInvoice = (props) => {
  const { input, label, isCurrency, isButton, buttonText } = props;

  const isMobile76 = useMediaQuery({ query: `(max-width: 760px)` });

  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (input.modelval) setInputValue(input.modelval);
  }, [input.modelval]);

  const changeHandler = (e) => {
    if (isCurrency) {
      const num = formatNumber(e.target.value);
      if (!isNaN(Number(num.replace(/,/g, '')))) {
        setInputValue(num);
      } else {
      }
    } else {
      setInputValue(e.target.value);
    }
  };
  const value = isCurrency && inputValue ? '$ ' + inputValue : inputValue;
  const width = isMobile76 ? 15 : 20;

  //TODO inputvalue comes from the global state via the api
  return (
    <div className={styles['input-box']}>
      <label className={styles['label']} htmlFor={input.id}>
        {label}
      </label>
      <input
        className={styles['input']}
        onChange={changeHandler}
        value={value}
        {...input}
      />
      {isButton && (
        <Fragment>
          <Button className={styles['button']} buttonText={buttonText} />
          <Info width={width} />
        </Fragment>
      )}
    </div>
  );
};

export default InputProcessInvoice;
