// libs
import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";

// components
import { BrowserRouter, useLocation } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import App from "./App";

// store
import store from "./store/index";

// css
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";

// GA4 Page View Tracker
const GA4PageViewTracker = () => {
  const location = useLocation();

  useEffect(() => {
    // Track page views on route changes
    window.gtag("config", "G-9Q0YPFZ7HD", { page_path: location.pathname });
  }, [location]);

  return null;
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ScrollToTop />
        <GA4PageViewTracker />
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
